import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Courses = (props) => {
  const [courses, setCourses] = useState([]);
  const [query, setQuery] = useState('');

  useEffect(() => {
    const obtenerCourses = async () => {
      const url = 'https://siliconback.juanromero.ar/courseslistfront';
      const result = await axios.get(url);
      setCourses(result.data);
    };
    obtenerCourses();
  }, []);

  const filteredCourses = courses.filter((course, index) => {
    if (index === 0) {
      return false; // Filter out the first course
    }
    const courseText = `${course.courses_title} ${course.courses_description} ${course.courses_teacher}`;
    return courseText.toLowerCase().includes(query.toLowerCase());
  });

  return (
    <div className="col-lg-8 mx-auto p-4 py-md-5">
      <header className="d-flex align-items-center pb-3 mb-5 border-bottom">
        <a href="/" className="d-flex align-items-center text-dark text-decoration-none">
          <h1>Courses</h1>
        </a>
      </header>
      <main>
      <div className="col-md-12 mb-3">
            <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search courses by title, description, or teacher"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />  
              </div>
       </div>
        <div className="container py-4">
          <div className="row align-items-md-stretch">

            {filteredCourses.length === 0 && <p>No courses found.</p>}
            {filteredCourses.map((course, i) => (
              <div className="col-md-6 mb-3" key={i}>
                <div className="h-100 p-5 bg-light border rounded-3">
                  <div>
                    <h2 className="blog-post-title mb-1">{course.courses_title}</h2>
                    <p>
                      {course.courses_description} - Profesor: {course.courses_teacher}
                    </p>
                    {course.courses_url &&
                      <a href={course.courses_url}>
                        <button className="btn btn-outline-secondary" type="button">
                          Link
                        </button>
                      </a>
                    }
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Courses;
