import React, { useState, useEffect } from "react";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const News = (props) => {
  const [news, setNews] = useState([]);
  const [readItems, setReadItems] = useState([]);

  useEffect(() => {
    const obtenerNews = async () => {
      //const url = 'http://localhost:3001/newslistfront';
      const url = 'https://siliconback.juanromero.ar/newslistfront';
      const result = await axios.get(url);
      setNews(result.data);
    };
    obtenerNews();
  }, []);

  useEffect(() => {
    const storedReadItems = JSON.parse(localStorage.getItem('readItems'));
    if (storedReadItems) {
      setReadItems(storedReadItems);
    }
  }, []);

  const markItemAsRead = (index) => {
    const newReadItems = [...readItems, index];
    setReadItems(newReadItems);
    localStorage.setItem('readItems', JSON.stringify(newReadItems));
  }

  const isItemRead = (index) => {
    return readItems.includes(index);
  }

  return (
    <div className="col-lg-8 mx-auto p-4 py-md-5">
      <header className="d-flex align-items-center pb-3 mb-5 border-bottom">
        <a href="/" className="d-flex align-items-center text-dark text-decoration-none">
          <h1>News</h1>
        </a>
      </header>
      <div>
          {news.length === 0 && <p>Cargando...</p>}
          {news.map((item, index) => (
            <div key={index}>
              <div className={`card text-bg-light mb-3 ${isItemRead(index) ? 'bg-secondary text-white' : ''}`} style={{ maxWidth: '18rem' }}>
                <div className="card-header">{new Date(item.datetime).toDateString()}</div>
                <div className="card-body">
                  <h5 className="card-title">{item.title}</h5>
                  <p className="card-text">{item.description}</p>
                  <p className="card-text"><small className="text-muted">Post by {item.name} {item.surname}</small></p>
                  {!isItemRead(index) && (
                    <button className="btn btn-outline-secondary" onClick={() => markItemAsRead(index)}>
                      <FontAwesomeIcon icon={faCheck} /> Marcar como leído
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default News;