import 'bootstrap/dist/css/bootstrap.min.css';
import Nav from './components/Nav';
import Footer from './components/Footer';
import './App.css';

function App() {
  return (
    <div className="App">
      <Nav />
      <Footer/>  
    </div>
  );
}

export default App;
