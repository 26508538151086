import React from 'react';

const Footer = (props) => {
    return (
        <div className="container">
            <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                    <p className="col-md-4 mb-0 text-muted">&copy; 2022 Juan Romero</p>

                    <ul className="nav col-md-4 justify-content-end">
                    <li className="nav-item"><a href="https://juanromero.ar"  className="nav-link px-2 text-muted" target="_blank" rel="noreferrer">Portfolio</a></li>
                </ul>
            </footer>
        </div>
    );
}

export default Footer;