import '../styles/pages/Home.css';
import Courses from './Courses';

function Home() {
  return (
    <>
    <div className="col-lg-8 mx-auto p-4 py-md-5">
      <header className="pb-3 mb-4 border-bottom">
        <a href="/" className="d-flex align-items-center text-dark text-decoration-none">
        <h1>Home</h1>
        </a>
      </header>
      <main>
        <div className="container py-4">
            <div className="p-5 mb-4 bg-light rounded-3">
              <div className="container-fluid py-5">
                  <h1 className="display-5 fw-bold">Silicon Misiones</h1>
                  <p className="col-md-12 fs-4">Site made by students for students</p>
              </div>
            </div>
        </div>
      </main>
      
    </div> 
    <Courses/>
    </>
  );
}

export default Home;