import React from 'react';
import { useState, useEffect } from "react";
import axios from 'axios';


const Blog = (props) => {
    const [posts, setposts]=useState([]);
    useEffect(()=>{
        const obtenerPosts = async () => {
            //const url = 'http://localhost:3001/postslistblog'
            const url = 'https://siliconback.juanromero.ar/postslistblog'
            const result = await axios.get(url)
            console.log(result)
            setposts(result.data)
        }
        obtenerPosts()
    },[])

    return (
        <div className="col-lg-8 mx-auto p-4 py-md-5">
        <header className="d-flex align-items-center pb-3 mb-5 border-bottom">
            <a href="/" className="d-flex align-items-center text-dark text-decoration-none">
            <h1>Blog</h1>
            </a>
        </header>

        <div>
            {posts.length === 0 && <p>Cargando...</p>}
            {
                posts.map((posts, i)=>{
                        return (
                            <div key={i}>
                                <h2 className="blog-post-title mb-1">{posts.title}</h2>
                                <p className="blog-post-meta">Date: {new Date(posts.datetime).toDateString()} post by {posts.name} {posts.surname}</p>
                                <h6>{posts.description}</h6>
                                <p>{posts.body}</p>
                                <hr className="col-3 col-md-2 mb-5"/>
                            </div>
                        )
                })
            }
        </div>
        </div>
    );
}

export default Blog;

