import React from 'react';

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
    
} from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Home from '../routes/Home';
import Blog from '../routes/Blog';
import Documents from '../routes/Documents';
import Videos from '../routes/Videos';
import News from '../routes/News';
import Courses from '../routes/Courses';

function CollapsibleExample() {
    return (
        <Router>
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Container>
                <Navbar.Brand as={Link} to={"/"}>Students</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                    <Nav.Link as={Link} to={"/"}>Home</Nav.Link>
                    <Nav.Link as={Link} to={"/Documents"}>Documents</Nav.Link>
                    <Nav.Link as={Link} to={"/Videos"}>Videos</Nav.Link>
                    <Nav.Link as={Link} to={"/Blog"}>Blog</Nav.Link>
                    <Nav.Link as={Link} to={"/News"}>News</Nav.Link>
                    <Nav.Link as={Link} to={"/Courses"}>Courses</Nav.Link>
                    </Nav>
                    <Nav>
                        <Nav.Link href="https://siliconback.juanromero.ar/register">Sign in</Nav.Link>
                        <Nav.Link href="https://siliconback.juanromero.ar">Login</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                </Container>
            </Navbar>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/documents" element={<Documents />} />
                <Route path="/videos" element={<Videos />} />
                <Route path="/News" element={<News />} />
                <Route path="/Courses" element={<Courses />} />
                {/*<Route path="/Login" element={<Login />} /> */}
            </Routes>
        </Router>    
    );
  }
  
export default CollapsibleExample;


