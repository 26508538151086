import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileText, faStar, faPlus } from '@fortawesome/free-solid-svg-icons'

const Videos = (props) => {
  const [videos, setVideos] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [favourites, setFavourites] = useState('');

  useEffect(() => {
    const obtenerVideos = async () => {
      //const url = 'http://localhost:3001/videoslistFront';
      const url = 'https://siliconback.juanromero.ar/videoslistFront';
      const result = await axios.get(url);
      setVideos(result.data);
    };
    obtenerVideos();
  }, []);

  useEffect(() => {
    const storedFavourites = JSON.parse(localStorage.getItem('favourites'));
    if (storedFavourites) {
      setFavourites(storedFavourites);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('favourites', JSON.stringify(favourites));
  }, [favourites]);

  const toggleFavourite = (id) => {
    setFavourites(prevFavourites => {
      const updatedFavourites = { ...prevFavourites };
      updatedFavourites[id] = !updatedFavourites[id];
      return updatedFavourites;
    });
  };

  const filteredVideos = videos.filter(doc =>
    doc.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    doc.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    doc.courses_title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="col-lg-8 mx-auto p-4 py-md-5">
      <header className="d-flex align-items-center pb-3 mb-5 border-bottom">
        <a href="/" className="d-flex align-items-center text-dark text-decoration-none">
          <h1>Videos</h1>
        </a>
      </header>
      <div>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="Search by title, description or courses"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </div>
        <br/>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Title</th>
              <th scope="col">Description</th>
              <th scope="col">Courses</th>
              <th scope="col">Download</th>
              <th scope="col">Date</th>
              <th scope="col">post by</th>
              <th scope="col">Favourites</th>
            </tr>
          </thead>
          <tbody>
            {filteredVideos.length === 0 && <tr><td colSpan="8">No videos found</td></tr>}
            {filteredVideos.map((doc, i) => (
              <tr key={i}>
                <th scope="row">{doc.idvideos}</th>
                <td>{doc.title}</td>
                <td>{doc.description}</td>
                <td>{doc.courses_title}</td>
                <td><a target="_blank" rel="noreferrer" href={`${doc.url}`}><FontAwesomeIcon icon={faFileText} /></a></td>
                <td>{new Date(doc.datetime).toDateString()}</td>
                <td>{doc.name} {doc.surname}</td>
                <td>
                <button
                    className="btn btn-link"
                    onClick={() => toggleFavourite(doc.idvideos)}
                  >
                    {favourites[doc.idvideos] ? (
                      <FontAwesomeIcon icon={faStar} />
                    ) : (
                      <FontAwesomeIcon icon={faPlus} />
                    )}
                </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Videos;
